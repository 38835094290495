import React from 'react';
import { Text, Divider } from 'wix-ui-tpa';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { useEnvironment, useExperiments, useTranslation } from '@wix/yoshi-flow-editor';
import { TiersProgramSettings } from '@wix/ambassador-loyalty-v1-tier/build/cjs/types.impl';

import { IMAGE_ROUTE } from '../../../constants/image-route';
import { SimpleReward, SimpleRule, SimpleTier } from '../../../types/domain';
import { classes, style, vars } from './tier-cards.st.css';
import settingsParams from '../settingsParams';
import { Experiments } from '../../../constants/experiments';
import { alignmentToStateMap } from '../../../utils/alignment-to-state-map';
import stylesParams, { Alignment, BulletStyle, PointsTextPosition, TiersLayout } from '../stylesParams';
import { BulletThumbnail } from './bullet-thumbnail';
import { getShadowOffsets } from '../../../utils/get-shadow-angles';

interface TierCardsProps {
  simpleTiers: SimpleTier[];
  simpleRules: SimpleRule[];
  simpleRewards: SimpleReward[];
  cardsPerRow: number;
  cardsCount: number;
  showDevColors: boolean;
  layout: TiersLayout;
  tiersProgramSettings: TiersProgramSettings;
}

type DetailsItem = {
  title: string;
  description: string;
};

const layoutToStateMap = {
  [TiersLayout.Cards]: 'cards',
  [TiersLayout.List]: 'list',
};

const positionToStateMap = {
  [PointsTextPosition.Above_Title]: 'above_title',
  [PointsTextPosition.Under_Title]: 'under_title',
  [PointsTextPosition.Under_Description]: 'under_description',
};

export const TierCards: React.FC<TierCardsProps> = ({
  simpleTiers,
  simpleRules,
  simpleRewards,
  cardsPerRow,
  cardsCount,
  showDevColors,
  layout,
  tiersProgramSettings,
}) => {
  const settings = useSettings();
  const styles = useStyles();
  const { t } = useTranslation();
  const { experiments } = useExperiments();
  const { isMobile, isRTL } = useEnvironment();
  const showTiersDevTools = experiments.enabled(Experiments.ShowTiersDevTools);
  const alignment = styles.get(stylesParams.tiersContentAlignment) as Alignment;
  const showTierIcon = styles.get(stylesParams.tiersShowIcon) as Boolean;
  const showTierDescription = styles.get(stylesParams.tiersShowDescription) as Boolean;
  const pointsTextPosition = styles.get(stylesParams.tiersPointsTextPosition) as PointsTextPosition;
  const bulletThumbnailStyle = styles.get(stylesParams.tiersBulletsStyle) as BulletStyle;
  const shadowOffsets = getShadowOffsets({
    angle: styles.get(stylesParams.tiersShadowAngle),
    distance: styles.get(stylesParams.tiersShadowDistance),
  });

  const baseTier: SimpleTier = {
    title: tiersProgramSettings.baseTierDefinition?.name ?? '',
    description: tiersProgramSettings.baseTierDefinition?.description ?? '',
    iconUrl: tiersProgramSettings.baseTierDefinition?.icon?.url ?? '',
  };

  const renderDetails = (details: DetailsItem[]) =>
    details.map((detail, index) => (
      <div key={index} className={classes.detailContainer}>
        {alignment !== Alignment.Right ? (
          bulletThumbnailStyle === BulletStyle.None ? null : (
            <div className={classes.detailBulletContainer}>
              <BulletThumbnail type={bulletThumbnailStyle} size={17} />
            </div>
          )
        ) : null}
        <li aria-label={t('app.tiers.role')} className={classes.detailTextContainer}>
          <Text className={classes.detailTitle}>{detail.title}</Text>
          <Text className={classes.detailDescription}>{detail.description}</Text>
        </li>
        {alignment === Alignment.Right ? (
          bulletThumbnailStyle === BulletStyle.None ? null : (
            <div className={classes.detailBulletContainer}>
              <BulletThumbnail type={bulletThumbnailStyle} size={17} />
            </div>
          )
        ) : null}
      </div>
    ));

  const TierCards = () => {
    const cards = [baseTier, ...simpleTiers].map((tier, index) => (
      <li className={classes.tierCard}>
        <div
          className={style(classes.tierCardHeader, {
            pointsTextPosition: positionToStateMap[pointsTextPosition],
          })}
        >
          <div className={classes.tierCardTitleA11y}>
            <div className={classes.tierCardTitleContainer}>
              {alignment === Alignment.Left || alignment === Alignment.Center ? (
                <>
                  {showTierIcon && tier.iconUrl ? (
                    <img
                      alt={t('app.image.tier-icon')}
                      aria-hidden
                      className={classes.tierCardIcon}
                      src={IMAGE_ROUTE(tier.iconUrl)}
                    />
                  ) : null}
                </>
              ) : null}
              <Text tagName="h3" className={classes.tierCardTitle}>
                {tier.title}
              </Text>
              {alignment === Alignment.Right ? (
                <>
                  {showTierIcon && tier.iconUrl ? (
                    <img
                      alt={t('app.image.tier-icon')}
                      aria-hidden
                      className={classes.tierCardIcon}
                      src={IMAGE_ROUTE(tier.iconUrl)}
                    />
                  ) : null}
                </>
              ) : null}
            </div>
            {pointsTextPosition === PointsTextPosition.Above_Title ? (
              tier.requiredPoints ? (
                <Text className={classes.tierCardPoints}>
                  {t('app.tiers.points-needed', { requiredPoints: tier.requiredPoints })}
                </Text>
              ) : (
                <Text aria-hidden className={classes.tierCardPointsBase}>
                  ''
                </Text>
              )
            ) : null}
          </div>
          {pointsTextPosition === PointsTextPosition.Under_Title ? (
            tier.requiredPoints ? (
              <Text className={classes.tierCardPoints}>
                {t('app.tiers.points-needed', { requiredPoints: tier.requiredPoints })}
              </Text>
            ) : (
              <Text aria-hidden className={classes.tierCardPointsBase}>
                ''
              </Text>
            )
          ) : null}
          {showTierDescription ? (
            <>
              <Text className={classes.tierCardDescription}>{tier.description}</Text>
              {pointsTextPosition === PointsTextPosition.Under_Description ? (
                tier.requiredPoints ? (
                  <Text className={classes.tierCardPoints}>
                    {t('app.tiers.points-needed', { requiredPoints: tier.requiredPoints })}
                  </Text>
                ) : (
                  <Text aria-hidden className={classes.tierCardPointsBase}>
                    ''
                  </Text>
                )
              ) : null}
            </>
          ) : null}
        </div>
        <Divider className={classes.tierCardDivider} />
        <div className={classes.sectionsContainer}>
          <div className={classes.section}>
            <Text className={classes.sectionTitle}>{settings.get(settingsParams.earnPointsTitle)}</Text>
            <ul>{renderDetails(simpleRules.filter((rule) => rule.tierId === tier.id))}</ul>
          </div>
          <div className={classes.section}>
            <Text className={classes.sectionTitle}>{settings.get(settingsParams.redeemPointsTitle)}</Text>
            <ul>{renderDetails(simpleRewards.filter((rule) => rule.tierId === tier.id))}</ul>
          </div>
        </div>
      </li>
    ));

    if (cardsCount > cards.length) {
      let moreCards = [...cards];
      for (let i = cards.length; i < cardsCount; i++) {
        moreCards.push(cards[cards.length - 1]);
      }
      return moreCards;
    } else if (cardsCount < cards.length) {
      return cards.slice(0, cardsCount);
    }
    return cards;
  };

  return (
    <ol
      className={style(classes.root, {
        cardsPerRow,
        lastCardsCount: TierCards().length % cardsPerRow,
        showDevColors: showDevColors && showTiersDevTools,
        alignment: alignmentToStateMap[alignment],
        showShadow: styles.get(stylesParams.tiersShowShadow),
        layout: layoutToStateMap[layout],
        isMobile,
        isRTL,
      })}
      style={{
        [vars.shadowXOffset]: shadowOffsets.xOffset + 'px',
        [vars.shadowYOffset]: shadowOffsets.yOffset + 'px',
      }}
    >
      {TierCards()}
    </ol>
  );
};
