import React from 'react';
import { useEnvironment} from '@wix/yoshi-flow-editor';
import { useStyles, useSettings } from '@wix/tpa-settings/react';
import { LoyaltyProgram } from '@wix/ambassador-loyalty-v1-program/types';

import { SimpleReward, SimpleRule } from '../../../types/domain';
import { Header } from './header';
import stylesParams, { PageLayout } from '../stylesParams';
import { TabState } from '../../../types/settings';
import { DetailsCard } from './details-card';
import { classes, style } from './main-program.st.css';
import settingsParams from '../settingsParams';
import { DataHook } from '../../../types/data-hook';

interface MainProgramProps {
  tabState: TabState;
  loyaltyProgram: LoyaltyProgram;
  isLoggedIn: boolean;
  isMyRewardsPageInstalled: boolean;
  navigateToMyRewards: () => void;
  simpleRules: SimpleRule[];
  simpleRewards: SimpleReward[];
  showTiers: boolean;
}

const layoutToStateMap = {
  [PageLayout.Grid]: 'grid',
  [PageLayout.List]: 'list',
  [PageLayout.Side]: 'side',
};

export const MainProgram: React.FC<MainProgramProps> = ({
  tabState,
  loyaltyProgram,
  isLoggedIn,
  isMyRewardsPageInstalled,
  navigateToMyRewards,
  simpleRules,
  simpleRewards,
  showTiers,
}) => {
  const styles = useStyles();
  const settings = useSettings();
  const { isMobile, isRTL } = useEnvironment();
  const pageLayout =
    !styles.get(stylesParams.showHeader) || !styles.get(stylesParams.showProgramContent)
      ? PageLayout.List
      : (styles.get(stylesParams.pageLayout) as PageLayout);
  const iconPath = loyaltyProgram?.pointDefinition?.icon?.url;

  return (
    <div
      className={style(classes.root, {
        pageLayout: isMobile ? 'mobile' : layoutToStateMap[pageLayout],
        isRTL,
      })}
      data-hook={DataHook.LoyaltyPageWrapper}
    >
      {styles.get(stylesParams.showHeader) ? (
        <Header
          loyaltyProgram={loyaltyProgram}
          isLoggedIn={tabState ? tabState === TabState.AfterSignUp : isLoggedIn}
          isMyRewardsPageInstalled={isMyRewardsPageInstalled}
          navigateToMyRewards={navigateToMyRewards}
        />
      ) : null}
      {styles.get(stylesParams.showProgramContent) ? (
        <ol className={classes.detailsContainer}>
          <DetailsCard
            dataHook={DataHook.DetailsCardSignUp}
            step={1}
            iconPath={iconPath ?? ''}
            title={settings.get(settingsParams.signUpTitle)}
            details={[{ description: settings.get(settingsParams.signUpDescription) }]}
            showTiers={showTiers}
          />
          <DetailsCard
            dataHook={DataHook.DetailsCardEarnPoints}
            step={2}
            iconPath={iconPath ?? ''}
            title={settings.get(settingsParams.earnPointsTitle)}
            details={
              showTiers
                ? [{ description: settings.get(settingsParams.earnPointsDescription) }]
                : simpleRules.filter((rule) => !rule.tierId)
            }
            showTiers={showTiers}
          />
          <DetailsCard
            dataHook={DataHook.DetailsCardRedeemPoints}
            step={3}
            iconPath={iconPath ?? ''}
            title={settings.get(settingsParams.redeemPointsTitle)}
            details={
              showTiers
                ? [{ description: settings.get(settingsParams.redeemPointsDescription) }]
                : simpleRewards.filter((reward) => !reward.tierId)
            }
            showTiers={showTiers}
          />
        </ol>
      ) : null}
    </div>
  );
};
