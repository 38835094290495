import React, { useEffect, useState } from 'react';
import { Text, Button, ButtonSize, Counter } from 'wix-ui-tpa';
import { TiersProgramSettings } from '@wix/ambassador-loyalty-v1-tier/build/cjs/types.impl';
import { useSettings, useStyles } from '@wix/tpa-settings/react';
import { useEnvironment, useExperiments } from '@wix/yoshi-flow-editor';

import { Experiments } from '../../../constants/experiments';
import { SimpleReward, SimpleRule, SimpleTier } from '../../../types/domain';
import settingsParams from '../settingsParams';
import stylesParams, { Alignment, TiersLayout } from '../stylesParams';
import { TierCards } from './tier-cards';
import { classes, style } from './tiers-program.st.css';
import { DataHook } from '../../../types/data-hook';
import { alignmentToStateMap } from '../../../utils/alignment-to-state-map';

interface TiersProgramProps {
  simpleTiers: SimpleTier[];
  simpleRules: SimpleRule[];
  simpleRewards: SimpleReward[];
  tiersProgramSettings: TiersProgramSettings;
}

export const TiersProgram: React.FC<TiersProgramProps> = ({
  simpleTiers,
  simpleRules,
  simpleRewards,
  tiersProgramSettings,
}) => {
  const { experiments } = useExperiments();
  const styles = useStyles();
  const settings = useSettings();
  const { isMobile } = useEnvironment();
  const [showTiersTools, setShowTiersTools] = useState(false);
  const [showDevColors, setShowDevColors] = useState(false);
  const [cardsPerRow, setCardsPerRow] = useState(isMobile ? 1 : styles.get(stylesParams.tiersCardsPerRow));
  const [cardsCount, setCardsCount] = useState(simpleTiers.length + 1);
  const [layout, setLayout] = useState<TiersLayout>(
    isMobile ? TiersLayout.Cards : styles.get(stylesParams.tiersLayout),
  );
  const showTiersDevTools = experiments.enabled(Experiments.ShowTiersDevTools);
  const settingsTiersLayout = styles.get(stylesParams.tiersLayout);
  const settingsCardsPerRow = styles.get(stylesParams.tiersCardsPerRow);
  const showSubtitle = styles.get(stylesParams.showTiersPageSubtitle);

  useEffect(() => {
    if (isMobile) {
      setCardsPerRow(1);
    } else {
      setCardsPerRow(settingsCardsPerRow);
    }
  }, [settingsCardsPerRow]);

  useEffect(() => {
    if (isMobile) {
      setLayout(TiersLayout.Cards);
    } else {
      setLayout(settingsTiersLayout);
    }
  }, [settingsTiersLayout]);

  console.log('Alignment: ', alignmentToStateMap[styles.get(stylesParams.tiersHeaderAlignment) as Alignment]);

  const TiersDevTools: React.FC = () => {
    return (
      <>
        <div style={{ marginBottom: '10px' }}>
          <Button onClick={() => setShowTiersTools(!showTiersTools)}>
            {showTiersTools ? 'Hide Tools' : 'Show Tools'}
          </Button>
        </div>
        {showTiersTools ? (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: '1fr 1fr 1fr 1fr',
              alignItems: 'end',
              justifyItems: 'center',
              marginBottom: '20px',
            }}
          >
            <Button onClick={() => setShowDevColors(!showDevColors)}>
              {showDevColors ? 'Hide Dev Colors' : 'Show Dev Colors'}
            </Button>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
              }}
            >
              <Text>Layout</Text>
              <Button
                onClick={() =>
                  setLayout(
                    layout === TiersLayout.Cards
                      ? TiersLayout.List
                      : layout === TiersLayout.List
                      ? TiersLayout.Cards
                      : 0,
                  )
                }
              >
                {layout === TiersLayout.Cards ? 'Cards' : 'List'}
              </Button>
            </div>
            {layout === TiersLayout.Cards ? (
              <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Text>Cards per row</Text>
                <div style={{ gap: '10px', gridTemplateColumns: '1fr 1fr 1fr', display: 'grid' }}>
                  <Button size={ButtonSize.tiny} onClick={() => setCardsPerRow(1)} disabled={cardsPerRow === 1}>
                    1
                  </Button>
                  <Button size={ButtonSize.tiny} onClick={() => setCardsPerRow(2)} disabled={cardsPerRow === 2}>
                    2
                  </Button>
                  <Button size={ButtonSize.tiny} onClick={() => setCardsPerRow(3)} disabled={cardsPerRow === 3}>
                    3
                  </Button>
                </div>
              </div>
            ) : null}
            <Counter
              label="Cards Count"
              inputAriaLabel="quantity"
              incrementAriaLabel="increment"
              decrementAriaLabel="decrement"
              value={cardsCount}
              onChange={(value) => setCardsCount(Number(value))}
            />
          </div>
        ) : null}
      </>
    );
  };

  return (
    <div
      className={style(classes.root, {
        showDevColors: showDevColors && showTiersDevTools,
        alignment: alignmentToStateMap[styles.get(stylesParams.tiersHeaderAlignment) as Alignment],
      })}
      data-hook={DataHook.TiersProgram}
    >
      <div className={classes.headerSection}>
        {showTiersDevTools ? <TiersDevTools /> : null}
        {styles.get(stylesParams.showTiersPageTitle) ? (
          <Text tagName="h2" className={style(classes.sectionTitle, { hideSubtitle: !showSubtitle })}>
            {settings.get(settingsParams.tiersTitle)}
          </Text>
        ) : null}
        {showSubtitle ? (
          <Text className={classes.sectionSubtitle}>{settings.get(settingsParams.tiersSubtitle)}</Text>
        ) : null}
      </div>
      <TierCards
        {...{
          simpleTiers,
          simpleRules,
          simpleRewards,
          cardsPerRow,
          cardsCount,
          showDevColors,
          layout,
          tiersProgramSettings,
        }}
      />
    </div>
  );
};
