import React, { FC } from 'react';
import { WidgetProps, useExperiments } from '@wix/yoshi-flow-editor';
import { LoyaltyProgram, ProgramStatus } from '@wix/ambassador-loyalty-v1-program/types';
import { useStyles } from '@wix/tpa-settings/react';
import { Status, TiersProgramSettings } from '@wix/ambassador-loyalty-v1-tier/types';

import { SimpleReward, SimpleRule, SimpleTier } from '../../../types/domain';
import { EmptyState } from './empty-state';
import { TabState } from '../../../types/settings';
import { ErrorState } from './error-state';
import { MainProgram } from './main-program';
import { TiersProgram } from './tiers-program';
import { Experiments } from '../../../constants/experiments';
import stylesParams from '../stylesParams';
import { DataHook } from '../../../types/data-hook';

export type ControllerProps = {
  hasError: boolean;
  tabState: TabState;
  loyaltyProgram: LoyaltyProgram;
  isLoggedIn: boolean;
  isMyRewardsPageInstalled: boolean;
  navigateToMyRewards: () => void;
  simpleRules: SimpleRule[];
  simpleRewards: SimpleReward[];
  hasVipPlan: boolean;
  tiersProgramSettings: TiersProgramSettings;
  simpleTiers: SimpleTier[];
};

const Widget: FC<WidgetProps<ControllerProps>> = ({
  hasError,
  tabState,
  loyaltyProgram,
  isLoggedIn,
  navigateToMyRewards,
  isMyRewardsPageInstalled,
  simpleRules,
  simpleRewards,
  hasVipPlan,
  tiersProgramSettings,
  simpleTiers,
}) => {
  const { experiments } = useExperiments();
  const styles = useStyles();
  const tiersEnabled = experiments.enabled(Experiments.ShowTiers);
  const showTiers =
    hasVipPlan && styles.get(stylesParams.showTiers) && tiersProgramSettings.status === Status.ACTIVE && tiersEnabled;

  if (hasError) {
    return <ErrorState />;
  }

  if (loyaltyProgram.status !== ProgramStatus.ACTIVE) {
    return <EmptyState />;
  }

  return (
    <div data-hook={DataHook.LoyaltyWidget}>
      <MainProgram
        {...{
          tabState,
          loyaltyProgram,
          isLoggedIn,
          navigateToMyRewards,
          isMyRewardsPageInstalled,
          simpleRules,
          simpleRewards,
          showTiers,
        }}
      />
      {showTiers ? <TiersProgram {...{ simpleTiers, simpleRules, simpleRewards, tiersProgramSettings }} /> : null}
    </div>
  );
};

export default Widget;
